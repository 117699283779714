import { IonButton, IonCard, IonCardContent, IonCardTitle, IonContent, IonHeader, IonIcon, IonText, IonTitle } from '@ionic/react';
import './sidebarComponentStyle.css';
import { chevronForwardCircle, mapSharp } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { SidebarState, UserBlock } from '../../enums/enums';

type sideBarProps = {
  userBlock: String;
  sidebarExpanded: SidebarState;
  setSidebarExpanded: (sidebarExpanded: SidebarState) => void;
}

const SideBar: React.FC<sideBarProps> = ({ userBlock, sidebarExpanded, setSidebarExpanded }) => {

  useEffect(() => {
    setSidebarExpanded(SidebarState.EXPANDED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBlock]);

  const [mouseDownPos, setmouseDownPos] = useState(0);
  const [mouseUpPos, setmouseUpPos] = useState(0);

  useEffect(() => {
    const mousePosDiff = mouseDownPos - mouseUpPos;
    if (mousePosDiff < -50) {
      setSidebarExpanded(SidebarState.COLLAPSED);
    } else if (mousePosDiff > 5) {
      setSidebarExpanded(SidebarState.EXPANDED);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mouseUpPos]
  );



  return (
    <>
      <div id="menu-Container" onTouchStart={(e) => { setmouseDownPos(e.touches[0].clientX) }} onTouchEnd={(e) => { setmouseUpPos(e.changedTouches[0].clientX) }}>
        <IonHeader>
          <div className="menu-Title-Style">
            <img src="assets/images/SSEN_Logo_White.svg"></img>
            <IonTitle class="menu-Logo-Style">My Rota Block</IonTitle>
            <IonButton class="menu-Button-Style" shape="round" fill="outline" onClick={() => setSidebarExpanded(SidebarState.COLLAPSED)}>
              <IonText>Map</IonText>
              <IonIcon icon={mapSharp} slot="end"></IonIcon>
            </IonButton>
          </div>
        </IonHeader>
        <IonContent class="menu-content">
          <div className="block-Card-Style">
            {userBlock === "UNSET" ? (
              <>
                <IonCardTitle class="block-Card-Title">Find your Block Identifier</IonCardTitle>
                <IonCardContent class="block-Card-Content">
                  Find your block identifier by either:
                  <ul>
                    <li>Searching for your location.</li>
                    {window.innerWidth > 640 && (
                    <li>Clicking on your area on the map.</li>
                    )}
                    {window.innerWidth < 640 && (
                    <li>Tapping and holding on your area on the map.</li>
                    )}
                  </ul>
                </IonCardContent>
                {window.innerWidth < 640 && (
                  <IonButton class="information-Button-Style" onClick={() => setSidebarExpanded(SidebarState.COLLAPSED)} >Find Block Identifier on Map</IonButton>
                )}
              </>
            ) : userBlock === UserBlock.OUT_OF_BOUNDS ? (
              <>
                <IonCardTitle class="block-Card-Title">Outside SSEN Network Area</IonCardTitle>
                <IonCardContent class="information-Card-Content">
                  The location that has been selected is outwith our network boundary.
                  <br/><br/>
                  If you live in an area outside of our network boundary, please contact your local electricity distributor.
                </IonCardContent>
                {window.innerWidth < 640 && (
                  <IonButton class="information-Button-Style" onClick={() => setSidebarExpanded(SidebarState.COLLAPSED)} >Check another location</IonButton>
                )}
              </>
            ) :  userBlock ===  "anim-Loading-Bar" ? (
              <>
                <IonCardTitle class="block-Card-Title">Your block identifier</IonCardTitle>
                <IonCardContent>
                  <IonCard class="letter-Card-Style">
                    <IonCardTitle class="block-Letter-Loading"> <div className="spinning-Loading-Bar"></div></IonCardTitle>
                  </IonCard>
                </IonCardContent>
                {window.innerWidth < 640 && (
                  <IonButton class="information-Button-Style" onClick={() => setSidebarExpanded(SidebarState.COLLAPSED)} >Check another location</IonButton>
                )}
              </>
            ) : (
              <>
                <IonCardTitle class="block-Card-Title">Your block identifier</IonCardTitle>
                <IonCardContent>
                  <IonCard class="letter-Card-Style">
                    <IonCardTitle class="block-Letter-Text">{userBlock}</IonCardTitle>
                  </IonCard>
                </IonCardContent>
                {window.innerWidth < 640 && (
                  <IonButton class="information-Button-Style" onClick={() => setSidebarExpanded(SidebarState.COLLAPSED)} >Check another location</IonButton>
                )}
              </>
            )}
          </div>
          <div className="block-Card-Style">
            <IonCardTitle class="explanation-Card-Title">What is a "block identifier?"</IonCardTitle>
            <IonCardContent class="explanation-Card-Content">
              The whole country is split into areas based on postcodes and each is allocated an identifer which is linked to that area.
              If we are instructed to isolate power by the National Grid, we will do so in blocks using these letters.
              Furthermore, if we need to move to rolling power cuts, this is done on rota using these letters too.
            </IonCardContent>
          </div>
          <div className="block-Card-Style">
            <IonCardTitle class="information-Card-Title">Want to know more?</IonCardTitle>
            <div className="information-Card-Content">
              <IonText>Visit our Emergency Planning Portal for further information and advice.</IonText>
              <IonButton class="information-Button-Style" href=" https://www.ssen.co.uk/power-cuts-emergencies/emergency-planning/ ">EMERGENCY PLANNING PORTAL</IonButton>
            </div>
          </div>
        </IonContent>
        <div className='menu-Toggle' onClick={() => setSidebarExpanded(sidebarExpanded === SidebarState.EXPANDED ? SidebarState.COLLAPSED : SidebarState.EXPANDED)}>
          <IonIcon src={chevronForwardCircle} className={sidebarExpanded === SidebarState.EXPANDED ? "" : "rotate180deg"}></IonIcon>
        </div>
      </div>
    </>
  );
}

export default SideBar;
