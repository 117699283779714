import './Home.css';
import HeadingBar from '../components/headingBarComponent/headingBarComponent';
import SideBar from '../components/sidebarComponent/sidebarComponent';
import MapContent from '../components/mapContentComponent/mapContentComponent';
import { useState } from 'react';
import { SidebarState, UserBlock } from '../enums/enums';

function Home() {

  let [sidebarExpanded, setSidebarExpanded] = useState(SidebarState.EXPANDED);
  let [userBlock, setuserBlock] = useState<String>(UserBlock.UNSET);

  return (
    <>
      <HeadingBar></HeadingBar>
      <div id="page-Content" className={sidebarExpanded === SidebarState.EXPANDED ? '' : 'sidebar-Hidden'}>
        <MapContent setUserBlock={setuserBlock} />
        <SideBar userBlock={userBlock} sidebarExpanded={sidebarExpanded} setSidebarExpanded={setSidebarExpanded} />
      </div>
    </>
  );
}

export default Home;
