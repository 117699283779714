import { IonHeader, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import './headingBarComponentStyle.css';

function headingBar() {
  return (
    <IonHeader>
      <IonToolbar class="heading-Toolbar-Style">
        <div className='headingRow'>
          <img src="assets/images/SSEN_Logo.svg"></img>
        </div>
      </IonToolbar>
    </IonHeader>
  );
}

export default headingBar;