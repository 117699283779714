import './mapContentComponentStyle.css';
import 'mapbox-gl/dist/mapbox-gl.css';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { useRef, useEffect } from 'react';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import _ from 'lodash';
import { IonButton, IonIcon } from '@ionic/react';
import { add, refresh, remove, returnUpBack } from 'ionicons/icons';
import { UserBlock } from '../../enums/enums';



mapboxgl.accessToken = 'pk.eyJ1Ijoib3BlbmdyaWQiLCJhIjoiY2xhczQ0ZnJxMGN5ejQwb3ZnZ3h5cGdweiJ9.6s4liW7r_H4vj6P-bfJ4tw';

type mapContentProps = {
	setUserBlock: any;
}

const NAVIGATION_TIME_MS : number = 2000;
const SEARCH_TEST_MS : number = 250;
const SEARCH_ATTEMPT_COUNT : number = 10;

const MapContent: React.FC<mapContentProps> = ({ setUserBlock }) => {

	const mapboxContainer = useRef<any>(undefined);
	const map = useRef<any>(undefined);
	const buttonRef = useRef<any>(undefined);

	useEffect(() => {

		setUserBlock("UNSET");
		map.current = new mapboxgl.Map({
			container: 'mapbox-Container',
			style: 'mapbox://styles/opengrid/cl3n12p66002l15nyllbssdhr',
			center: [-2.6743, 54.8235],
			zoom: 5,
			minZoom: 4
		});

		map.current.dragRotate.disable();
		map.current.touchZoomRotate.disableRotation();

		const searchBar = new MapboxGeocoder({
			accessToken: mapboxgl.accessToken,
			mapboxgl: mapboxgl,
			marker: false,
			placeholder: 'Search for your location',
			bbox: [-9, 50, 2, 61],
			proximity: {
				longitude: -1.27,
				latitude: 51.75
			},

			reverseGeocode: true  // for 'coordinates -> address' mappings
		});
		map.current.addControl(searchBar);

		let colours: any = {};
		let darkColours: any = {};
		let stops: Array<Array<string>> = [];
		let darkStops: Array<Array<string>> = [];
		let colorProps = { property: 'block', type: "categorical", stops: stops };

		let blockSet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'Z'];
		// let networks = ["murphypowerdistribution", "ssen-scotland","enw"];

		let blocks = blockSet.length;
		for (let i = 0; i < blocks; i++) {
			let block: string = blockSet[i];
			let hsl = "hsl(" + (256 * i / blocks).toFixed(0) + ",50%,50%)";
			let dhsl = "hsl(" + (256 * i / blocks).toFixed(0) + ",75%,25%)";
			colours[block] = hsl;
			darkColours[block] = dhsl;
			stops.push([block, hsl]);
			darkStops.push([block, dhsl]);
		}

		map.current.on('style.load', () => {
			// Resize map to fit window
			map.current.resize();
			let networks = ["ssen-scotland", "ssen-southern"];
			var dno: string;
			for (let i = 0; i < networks.length; i++) {
				dno = networks[i];
				const loc = window.location;
        		let path; 
       			console.log(loc.port);
        		console.log(loc.protocol);
        		console.log(loc.hostname);
        		if (loc.hostname == "localhost" || loc.hostname == "127.0.0.1"){
         			 // This needs to explicitly point to S3
           			 path = "https://ssen-myrotablock-test.opcld.com";
       			}else{
            		// Going to assume that it's always on default port for http/https as it should be in production
            		path = loc.protocol + "//" + loc.hostname;
        		}		
        			path += "/public/tiles-v3/vector/";
        console.log(path);
				map.current.addSource(dno + '-tiles', {
					'type': 'vector',
					'tiles': [
						path + dno + '/{z}/{x}/{y}.pbf'
					],
					'minzoom': 4,
					'maxzoom': 12
				});

				let areaPaint: any = {
					'fill-color': colorProps,
					'fill-opacity': [
						'interpolate',
						// Set the exponential rate of change to 0.5
						['exponential', 0.5],
						['zoom'],
						// When zoom is 10, buildings will be 100% transparent.
						9,
						0.95,
						// When zoom is 18 or higher, buildings will be 100% opaque.
						17,
						0.25
					]
				};

				map.current.addLayer(
					{
						'id': dno + '-vector-tiles', // Layer ID
						'type': 'fill',
						'source': dno + '-tiles', // ID of the tile source created above
						// Source has several layers. We visualize the one with name 'sequence'.
						'source-layer': 'rld-block',
						'layout': {},
						'paint': areaPaint,
					}
				);
			}
		});

		var marker: mapboxgl.Marker;
		var lastSearchTarget: any;
		//var mapBearing: any;

		let searchAt : number = 0;
		searchBar.on('result', (e: any) => {
			searchAt = new Date().getTime();
			let mapboxContainer : any = document.getElementById('mapbox-Container');
			if (mapboxContainer!=null){
				mapboxContainer.style['pointer-events'] = 'none';
			}
			setTimeout(function(){
				if (mapboxContainer!=null){
					mapboxContainer.style['pointer-events'] = 'all';
				}
			}, (NAVIGATION_TIME_MS/2)); // Just make it 50% of the time to prevent then double clicking too soon
			if (marker) {
				marker.remove();
			}
			lastSearchTarget = e.result.center;
			map.current.flyTo({
				zoom: 12,
				duration: NAVIGATION_TIME_MS,
				center: e.result.center,
				essential: true
			})
			
			marker = new mapboxgl.Marker({
				draggable: true,
				color: '#005982'
			})
			.setLngLat(e.result.center)
			.addTo(map.current);
			console.log("Pin at "+e.result.center);
			var currentState = "?"
			var attemptCount = 0;
			var myFunc = setTimeout(searchEnd, SEARCH_TEST_MS);
			function searchEnd(){
				if (map.current.getBounds().contains(marker.getLngLat())) {
					//		map.current.setBearing(mapBearing);
					let xANDy = map.current.project(e.result.center);
					let point = new mapboxgl.Point(Math.trunc(xANDy.x), Math.trunc(xANDy.y));
					let features = map.current.queryRenderedFeatures(point, {
						layers: ['ssen-scotland-vector-tiles', 'ssen-southern-vector-tiles']
					});
					
					if (Object.keys(features).length != 0) {
						console.log("Test Time Feat")
						handleOverlay(features);
						currentState = "FEATURE";
					} else {
						console.log("Test Time OOB")
						setUserBlock(UserBlock.OUT_OF_BOUNDS);
						lastOverlay = "no_valid_feature_fake_id_for_validation_reasons";
						currentState = "OUT_OF_BOUNDS"
					}
				}
				// Have it re-run even if out of bounds in case the tile data is still loading
				if (attemptCount < SEARCH_ATTEMPT_COUNT ) {
					if (currentState == "?" || currentState == "OUT_OF_BOUNDS") { 
						setUserBlock('anim-Loading-Bar');
						attemptCount = attemptCount + 1;
						myFunc = setTimeout(searchEnd, SEARCH_TEST_MS);
					}
				}
			}
			marker.on('dragend', (e: any) => {
				let rawDropx = e.target._pos.x;
				let rawDropy = e.target._pos.y;
				let point = new mapboxgl.Point(rawDropx, rawDropy);

				let features = map.current.queryRenderedFeatures(point, {
					layers: ['ssen-scotland-vector-tiles', 'ssen-southern-vector-tiles']
				});
				if (Object.keys(features).length != 0) {
					handleOverlay(features);
				} else {
					setUserBlock(UserBlock.OUT_OF_BOUNDS);
					lastOverlay = "no_valid_feature_fake_id_for_validation_reasons";
				}
			});
		});
		{window.innerWidth > 640 && (
		map.current.on('click', (e: any) => {
			if (marker) {
				marker.remove();
			}
			marker = new mapboxgl.Marker({
				draggable: true,
				color: '#005982'
			})
				.setLngLat(e.lngLat)
				.addTo(map.current);
			let xANDy = map.current.project(e.lngLat);
			let point = new mapboxgl.Point(Math.trunc(xANDy.x), Math.trunc(xANDy.y));
			let features = map.current.queryRenderedFeatures(point, {
				layers: ['ssen-scotland-vector-tiles', 'ssen-southern-vector-tiles']
			});
			if (Object.keys(features).length != 0) {
				handleOverlay(features);
			} else {
				setUserBlock(UserBlock.OUT_OF_BOUNDS);
				lastOverlay = "no_valid_feature_fake_id_for_validation_reasons";
			}
			marker.on('dragend', (e: any) => {
				let rawDropx = e.target._pos.x;
				let rawDropy = e.target._pos.y;
				let point = new mapboxgl.Point(rawDropx, rawDropy);
				let features = map.current.queryRenderedFeatures(point, {
					layers: ['ssen-scotland-vector-tiles', 'ssen-southern-vector-tiles']
				});
				if (Object.keys(features).length != 0) {
					handleOverlay(features);
				} else {
					setUserBlock(UserBlock.OUT_OF_BOUNDS);
					lastOverlay = "no_valid_feature_fake_id_for_validation_reasons";
				}
			});
		})
	)};

		var lngLatHolder: any; 
		var myVar: any; 
		let touchStartTime : number = 0;
		map.current.on('touchstart', (e : any) => {
			lngLatHolder = e.lngLat;
			touchStartTime = new Date().getTime();
			//myVar = setTimeout(clickScript, 1000);
		});
		map.current.on('touchend', (e : any) => {
			if ((new Date().getTime() - touchStartTime) < 100){
				touchStartTime = 0;
				clickScript();
			}
			//clearTimeout(myVar);
		});

		function clickScript() {
			if (marker) {
				marker.remove();
			}
			marker = new mapboxgl.Marker({
				draggable: true,
				color: '#005982'
			})
				.setLngLat(lngLatHolder)
				.addTo(map.current);
			let xANDy = map.current.project(lngLatHolder);
			let point = new mapboxgl.Point(Math.trunc(xANDy.x), Math.trunc(xANDy.y));
			let features = map.current.queryRenderedFeatures(point, {
				layers: ['ssen-scotland-vector-tiles', 'ssen-southern-vector-tiles']
			});
			if (Object.keys(features).length != 0) {
				handleOverlay(features);
			} else {
				setUserBlock(UserBlock.OUT_OF_BOUNDS);
				lastOverlay = "no_valid_feature_fake_id_for_validation_reasons";
			}
			marker.on('dragstart', () => {
				clearTimeout(myVar);
			});
			marker.on('dragend', (e: any) => {
				let rawDropx = e.target._pos.x;
				let rawDropy = e.target._pos.y;
				let point = new mapboxgl.Point(rawDropx, rawDropy);
				let features = map.current.queryRenderedFeatures(point, {
					layers: ['ssen-scotland-vector-tiles', 'ssen-southern-vector-tiles']
				});
				if (Object.keys(features).length != 0) {
					handleOverlay(features);
				} else {
					setUserBlock(UserBlock.OUT_OF_BOUNDS);
					lastOverlay = "no_valid_feature_fake_id_for_validation_reasons";
				}
			});
		  }

		let lastOverlay: any;
		const handleOverlay = function (e: any) {
			let blockIds = (e[0].properties.id);
			if (blockIds != lastOverlay) {
				setUserBlock(e[0].properties.block);
				lastOverlay = blockIds;
			};
		}

		// Update mapbox canvas size on map container size change
		const resizer = new ResizeObserver(_.debounce(() => map.current.resize(), 25));
		resizer.observe(mapboxContainer.current);

		return (() => {
			// Disable map container resize observer on component unmount
			resizer.disconnect();
		});

	}, []);

	return (
		<>
			<div id='mapbox-Container' ref={mapboxContainer}></div>
			<div id='map-Buttons'>
				<div className='map-Button-Container'>
					<IonButton id='zoom-in' shape='round' onClick={() => map.current.zoomIn()}><IonIcon slot='icon-only' icon={add} /></IonButton>
					<label className='map-Button-Label'>Zoom In</label>
				</div>
				<div className='map-Button-Container'>
					<IonButton id='zoom-out' shape='round' onClick={() => map.current.zoomOut()}><IonIcon slot='icon-only' icon={remove} /></IonButton>
					<label className='map-Button-Label'>Zoom Out</label>
				</div>
				<div className='map-Button-Container'>
					<IonButton id='reset-to-uk-bounds' shape='round' onClick={() => map.current.fitBounds([[-10.5, 49.5], [2.5, 60.5]])}><IonIcon slot='icon-only' icon={refresh} /></IonButton>
					<label className='map-Button-Label'>Reset Map</label>
				</div>
				<div className='map-Button-Container'>
					<IonButton id='return-to-last-search-pos' shape='round' ref={buttonRef} ><IonIcon slot='icon-only' icon={returnUpBack} /></IonButton>
					<label className='map-Button-Label'>Last search position</label>
				</div>
			</div>
		</>
	);
}

export default MapContent;